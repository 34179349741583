/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class Courier extends BaseModel {
    static entityName = 'courier';

    defaults() {
        return {
            id: null,
            shortname: null,
            fullname: null,
            email: null,
            phone: null,
            created_at: null,
            account_ref: null
        }
    }
}
