<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    import groupBy from "lodash/groupBy";
    import Formatter from "../../utils/Formatter";

    export default {
        name: "ShipmentNomenclatureTable",
        props: {
            nomenclature: {
                type: Array,
                default: []
            }
        },
        computed: {
            groupedByVendor: function () {
                return groupBy(this.nomenclature, (item) => {
                    return item.vendorText || 'Прочее';
                });
            }
        },
        methods: {
            getWeightTotal(item) {
                return item.weight_kg && Formatter.asUnit(item.weight_kg * item.count, {unit: 'kilogram'}) || '';
            }
        }
    }
</script>


<template>
    <table>
        <colgroup>
            <col width="80%"></col>
            <col width="20%"></col>
        </colgroup>
        <tr>
            <th>Номенклатура</th>
            <th>Отгрузка</th>
        </tr>
        <template v-for="(items, vendor) in groupedByVendor">
            <tr>
                <td><span class="vendor-name">{{vendor}}</span></td>
                <td></td>
            </tr>
            <tr v-for="item in items">
                <td>
                    <div class="nomenclature-name">
                        {{item.name}} {{item.measureText}}
                    </div>
                </td>
                <td>{{item.count}}</td>
            </tr>
        </template>
    </table>
</template>


<style scoped lang="scss">
    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    th, td:not(:first-child) {
        text-align: center;
    }

    th, td {
        border-bottom: 1px solid #eee;
        margin: 0;
        padding: 5px;
    }

    .vendor-name {
        font-weight: bold;
        display: block;
        margin-top: 10px;
    }

    .nomenclature-name {
        margin-left: 20px;

        a {
            color: inherit;
        }
    }
</style>