/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import Formatter from "../utils/Formatter.js";
import numberToWordsRu from "number-to-words-ru";
import compact from "lodash/compact";
import chunk from "lodash/chunk";
import sumBy from "lodash/sumBy";
import findIndex from "lodash/findIndex";
import OrderPdfPrinter from "@/models/OrderPdfPrinter.js";

export default class OrderPdfPrinterLegal extends OrderPdfPrinter {
    getPdfDefinitions() {
        return {
            ...super.getPdfDefinitions(),
            defaultStyle: {
                fontSize: 9,
                bold: false
            }
        }
    }


    getPdfStyles() {
        return {
            OrderPdfPrinterLegal_header: {
                fontSize: 12,
                bold: true,
            },
            OrderPdfPrinterLegal_bold: {
                fontSize: 9,
                bold: true,
            },
            OrderPdfPrinterLegal_underline: {
                decoration: 'underline'
            },
            OrderPdfPrinterLegal_fieldName: {
                alignment: 'center',
                fontSize: 7,
            },
            OrderPdfPrinterLegal_small: {
                fontSize: 7
            },
            OrderPdfPrinterLegal_pageNumber: {
                italics: true
            }
        }
    }


    getTitle() {
        let order = this.order;
        return `ТОВАРНАЯ НАКЛАДНАЯ №${order.number} от ${Formatter.asDate(order.delivery_date, "LL")}`;
    }


    prepareData() {
        let order = this.order;
        let counterparty = order.getCounterparty();
        let docDate = this.order.delivery_date;
        let chunkedItems = this.splitItemsToChunks(order.items);

        return {
            order: {...order.attributes},
            vendorText: 'Крестьянское (фермерское) хозяйство Богачева Алла Константиновна, ИНН 782703656689, 188800, ' +
                'Ленинградская обл, Выборгский р-н, Выборг г, Вокзальная ул, дом 7, квартира 29',
            customerText: `${order.client_name}, ${order.delivery_address}, ${order.client_phone}`,
            courierText: order.courierName || null,

            totalText: `Всего наименований ${order.items.length}, на сумму ${Formatter.asCurrency(order.total)}`,
            totalByValue: Formatter.asDecimal(order.total),
            totalByWords: numberToWordsRu.convert(order.total),

            nds: order.nds || 0,
            ndsText: order.ndsText || 'Без НДС',
            itemMeasureText: 'шт',
            storehouse: 'Основной склад',
            reasonText: 'Основной договор',

            counterparty,
            consigneeRequisitesText: compact([
                order.client_name,
                counterparty.inn && `ИНН ${counterparty.inn}`,
                order.delivery_address,
                counterparty.bank_account && `р/с ${counterparty.bank_account}`,
                counterparty.bank_name && `в банке ${counterparty.bank_name}`,
                counterparty.bank_id && `БИК ${counterparty.bank_id}`,
                counterparty.corr_account && `к/с ${counterparty.corr_account}`
            ]).join(", "),
            payerRequisitesText: compact([
                order.client_name,
                counterparty.inn && `ИНН ${counterparty.inn}`,
                counterparty.legal_address,
                counterparty.bank_account && `р/с ${counterparty.bank_account}`,
                counterparty.bank_name && `в банке ${counterparty.bank_name}`,
                counterparty.bank_id && `БИК ${counterparty.bank_id}`,
                counterparty.corr_account && `к/с ${counterparty.corr_account}`
            ]).join(", "),


            docDateText: Formatter.asDate(docDate),
            docDateDay: Formatter.asDate(docDate, "DD"),
            docDateMonthByWords: Formatter.asDate(docDate, "DD MMMM"),
            docDateYearByWords: Formatter.asDate(docDate, "YYYY года"),

            itemsCount: order.items.length,
            itemsCountByWords: numberToWordsRu.convert(order.items.length, {
                showNumberParts: {
                    fractional: false,
                },
                showCurrency: {
                    integer: false,
                }
            }),

            chunkedItems,
            pagesCount: chunkedItems.length
        }
    }


    /**
     * Split items to page chunks
     * */
    splitItemsToChunks(items) {
        let perPageCount = 20;
        let maxFirstPageCount = 5;
        let maxLastPageCount = 5;
        // the first chunk will be first max count items
        let chunks = [items.slice(0, maxFirstPageCount)];
        let remainingItems = items.slice(maxFirstPageCount);

        // if remaining, chunk it
        if (remainingItems.length) {
            chunks = chunks.concat(chunk(remainingItems, perPageCount));
        }

        // border case for last page
        let lastPage = chunks[chunks.length - 1];
        if (items.length > 1 && (chunks.length === 1 || lastPage.length > maxLastPageCount)) {
            // add new page with one item
            chunks.push([lastPage.pop()]);
        }
        return chunks;
    }


    /**
     * @inheritDoc
     * */
    getPdfContent(data) {
        // Helpers
        let noBorder = {border: [false, false, false, false]};
        let borderTop = {border: [false, true, false, false]};
        let gapCell = {text: '', ...noBorder};

        let formID = [
            {text: 'Унифицированная форма № ТОРГ-12', style: 'OrderPdfPrinterLegal_small', alignment: 'right'},
            {text: 'Утверждена постановлением Госкомстата России от 25.12.98 № 132', style: 'OrderPdfPrinterLegal_small', alignment: 'right'}
        ];

        let header = [
            {
                margin: [160, -30, 0, 0],
                columnGap: 10,
                columns: [
                    {width: 'auto', text: `ТОВАРНАЯ НАКЛАДНАЯ`, style: 'OrderPdfPrinterLegal_header', margin: [0, 18]},
                    {
                        width: 'auto',
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [{text: 'Номер документа'}, {text: 'Дата составления'}],
                                [{text: data.order.number, alignment: 'center', style: 'OrderPdfPrinterLegal_bold'}
                                    , {text: data.docDateText, alignment: 'center', style: 'OrderPdfPrinterLegal_bold'}],
                            ]
                        }
                    },
                ]
            }
        ];

        let counterparties = [{
            table: {
                widths: [85, '*', 40, 50],
                body: [
                    [
                        {text: '', ...noBorder, colSpan: 3},
                        '',
                        '',
                        {text: 'Коды', alignment: 'center'}
                    ],
                    [
                        {text: data.vendorText, ...noBorder, rowSpan: 2, colSpan: 2},
                        '',
                        {text: 'Форма по ОКУД', ...noBorder, alignment: 'right', noWrap: true},
                        {text: '0330212', style: 'OrderPdfPrinterLegal_bold', alignment: 'center'}
                    ],
                    [
                        '',
                        '',
                        {text: 'по ОКПО', ...noBorder, alignment: 'right'},
                        {text: ''}
                    ],
                    [
                        {text: '(организация-грузоотправитель, адрес, телефон, факс, банковские реквизиты)', ...borderTop, style: 'OrderPdfPrinterLegal_fieldName', colSpan: 2},
                        '',
                        {...gapCell},
                        {text: '', rowSpan: 2}
                    ],
                    [
                        {text: data.storehouse, alignment: 'center', ...noBorder, colSpan: 2},
                        '',
                        {...gapCell},
                        ''
                    ],
                    [
                        {text: '(структурное подразделение)', ...borderTop, style: 'OrderPdfPrinterLegal_fieldName', colSpan: 2},
                        '',
                        {text: 'Вид деятельности по ОКДП', ...noBorder, alignment: 'right', noWrap: true},
                        ''
                    ],

                    // The consignee
                    [
                        {text: 'Грузополучатель:', ...noBorder, alignment: 'right'},
                        {text: data.consigneeRequisitesText, ...noBorder},
                        {text: 'по ОКПО', alignment: 'right', ...noBorder},
                        ''
                    ],
                    [
                        {...gapCell},
                        {text: '(организация, адрес, телефон, факс, банковские реквизиты)', ...borderTop, style: 'OrderPdfPrinterLegal_fieldName'},
                        {...gapCell},
                        {text: '', rowSpan: 2}
                    ],

                    // The Vendor
                    [
                        {text: 'Поставщик:', ...noBorder, alignment: 'right'},
                        {text: data.vendorText, ...noBorder},
                        {text: 'по ОКПО', ...noBorder, alignment: 'right'},
                        ''
                    ],
                    [
                        {...gapCell},
                        {text: '(организация, адрес, телефон, факс, банковские реквизиты)', ...borderTop, style: 'OrderPdfPrinterLegal_fieldName'},
                        {...gapCell},
                        {text: '', rowSpan: 2}
                    ],

                    // The Payer
                    [
                        {text: 'Плательщик:', ...noBorder, alignment: 'right'},
                        {text: data.payerRequisitesText, ...noBorder},
                        {text: 'по ОКПО', ...noBorder, alignment: 'right'},
                        ''
                    ],
                    [
                        {...gapCell},
                        {text: '(организация, адрес, телефон, факс, банковские реквизиты)', ...borderTop, style: 'OrderPdfPrinterLegal_fieldName'},
                        {...gapCell},
                        ''
                    ],

                    //The reason
                    [
                        {text: 'Основание:', ...noBorder, alignment: 'right'},
                        {text: data.reasonText, ...noBorder},
                        {text: 'номер', alignment: 'right'},
                        ''
                    ],
                    [
                        {...gapCell},
                        {text: '(договор, заказ-наряд)', ...borderTop, style: 'OrderPdfPrinterLegal_fieldName'},
                        {text: 'дата', alignment: 'right'},
                        ''
                    ],
                    [
                        {...gapCell},
                        {text: 'Транспортная накладная', alignment: 'right', ...noBorder},
                        {text: 'номер', alignment: 'right'},
                        ''
                    ],
                    [
                        {...gapCell},
                        {...gapCell},
                        {text: 'дата', alignment: 'right'},
                        ''
                    ],
                    [
                        {...gapCell},
                        {...gapCell},
                        {text: 'Вид операции', ...noBorder, alignment: 'right', noWrap: true},
                        ''
                    ],
                ]
            }
        }];

        // Collect all pages tables
        let table = data.chunkedItems.reduce((current, chunk, i) => {
            current.push(this.buildPageTable(chunk, i, data));
            return current;
        }, []);

        // The totals by words
        let totals = [
            {
                ...(data.pagesCount === 1 ? {pageBreak: 'before'} : {}), // handle border case when one item is present
                margin: [120, 0, 0, 0],
                stack: [
                    {
                        columnGap: 15,
                        columns: [
                            {text: 'Товарная накладная имеет приложение на', width: 'auto'},
                            ...this.buildField(`${data.pagesCount} листах`),
                            'листах'
                        ]
                    },
                    {
                        columnGap: 15,
                        columns: [
                            {text: 'и содержит', width: 'auto'},
                            ...this.buildField(data.itemsCountByWords, 300, "(прописью)"),
                            'порядковых номеров записей'
                        ]
                    },
                    {
                        columnGap: 15,
                        columns: [
                            {text: '', width: 216},
                            {text: 'Масса груза (нетто)', width: 'auto'},
                            ...this.buildField(" ", 150, "(прописью)"),
                            {canvas: [{type: 'rect', x: 0, y: -7, w: 165, h: 18, lineWidth: 2}], width: 'auto'}
                        ]
                    },
                    {
                        columnGap: 15,
                        columns: [
                            {text: 'Всего мест', width: 'auto'},
                            ...this.buildField(" ", 150, "(прописью)"),
                            {text: 'Масса груза (брутто)', width: 'auto'},
                            ...this.buildField(" ", 150, "(прописью)"),
                            {canvas: [{type: 'rect', x: 0, y: -8, w: 165, h: 18, lineWidth: 2}], width: 'auto'}
                        ]
                    }
                ]
            }
        ];

        // The left signs column - vendor`s side
        let signLeft = [
            {
                columnGap: 15,
                columns: [
                    {text: 'Приложение (паспорта, сертификаты и т.п.) на', width: 'auto'},
                    ...this.buildField(" ", 80, "(прописью)"),
                    {text: 'листах', width: 'auto'}
                ]
            },
            {text: 'Всего отпущено на сумму', style: 'OrderPdfPrinterLegal_bold'},
            ...this.buildField({text: data.totalByWords, style: 'OrderPdfPrinterLegal_bold'}, 300, "(прописью)"),
            {
                columnGap: 10,
                columns: [
                    {text: 'Отпуск груза разрешил', width: '*'},
                    ...this.buildField(" ", 70, "(должность)"),
                    ...this.buildField(" ", 70, "(подпись)"),
                    ...this.buildField(" ", 100, "(расшифровка)"),
                ]
            },
            {
                columnGap: 10,
                columns: [
                    {text: 'Главный (старший) бухгалтер', style: 'OrderPdfPrinterLegal_bold', width: '*'},
                    ...this.buildField(" ", 70, "(подпись)"),
                    ...this.buildField(" ", 100, "(расшифровка)"),
                ]
            },
            {
                columnGap: 10,
                columns: [
                    {text: 'Отпуск груза произвел', width: '*'},
                    ...this.buildField(" ", 70, "(должность)"),
                    ...this.buildField(" ", 70, "(подпись)"),
                    ...this.buildField(" ", 100, "(расшифровка)"),
                ]
            },
            {
                margin: [0, 10, 0, 0],
                columnGap: 5,
                columns: [
                    {text: 'М.П.', alignment: 'center', width: '50%'},
                    ...this.buildField({text: data.docDateMonthByWords, alignment: 'center'}, 80),
                    {text: data.docDateYearByWords, width: '*'}
                ]
            }
        ];

        // The right sign side - The customer
        let signRight = [
            {
                columnGap: 10,
                columns: [
                    {text: 'По доверенности №', width: '*'},
                    ...this.buildField(" ", 80, ""),
                    {text: 'от', width: 'auto'},
                    ...this.buildField(" ", 160, ""),
                ]
            },
            {
                columnGap: 10,
                margin: [0, 10, 0, 0],
                columns: [
                    {text: 'выданной', width: '*'},
                    ...this.buildField(" ", 270, "(кем, кому (организация, должность, фамилия, и.о.))")
                ]
            },
            {
                columnGap: 15,
                columns: [
                    {text: '', width: '*'},
                    ...this.buildField(" ", 270)
                ]
            },
            {
                columnGap: 15,
                columns: [
                    {text: '', width: '*'},
                    ...this.buildField(" ", 270)
                ]
            },
            {
                columnGap: 10,
                margin: [0, 10, 0, 0],
                columns: [
                    {text: 'Груз принял', width: '*'},
                    ...this.buildField(" ", 80, "(должность)"),
                    ...this.buildField(" ", 70, "(подпись)"),
                    ...this.buildField(" ", 100, "(расшифровка)"),
                ]
            },
            {
                columnGap: 10,
                columns: [
                    {text: 'Груз получил грузополучатель', width: '*'},
                    ...this.buildField(" ", 80, "(должность)"),
                    ...this.buildField(" ", 70, "(подпись)"),
                    ...this.buildField(" ", 100, "(расшифровка)"),
                ]
            },
            {
                margin: [0, 10, 0, 0],
                columnGap: 5,
                columns: [
                    {text: 'М.П.', alignment: 'center', width: '50%'},
                    {text: '"       "', width: 'auto'},
                    ...this.buildField(" ", 60),
                    {text: "20        года", width: '*'}
                ]
            }
        ];

        // Collect signs
        let signs = [
            {
                margin: [0, 10, 0, 0],
                columnGap: 25,
                columns: [
                    {stack: signLeft, width: '50%', lineHeight: 1.2},
                    {canvas: [{type: 'line', x1: 0, y1: 0, x2: 0, y2: 160, lineWidth: 1}]},
                    {stack: signRight, width: '50%', lineHeight: 1.2}
                ]
            }
        ];


        /**
         * Collect all content together
         * */
        return [
            ...formID,
            ...counterparties,
            ...header,
            ...table,
            ...totals,
            ...signs
        ];
    }


    /**
     * Helper to build field with value, line and name
     * @param value - The string value
     * @param width - The cell width
     * @param name - The name will be drawed bottom underline
     * */
    buildField(value = "", width = 150, name = "") {
        return [{
            width: width,
            stack: [
                ...((typeof value === 'object') ? [value] : [{text: value, margin: [5, 0, 0, 0]}]),
                {
                    canvas: [{type: 'line', x1: 0, y1: 0, x2: width, y2: 0, lineWidth: 1}]
                },
                {text: name, style: 'OrderPdfPrinterLegal_fieldName'}
            ]
        }];
    }


    /**
     * Build table for each page number
     * @param chunkedItems - The array of items to page
     * @param currentChunkIndex - The page number index
     * @param data - The prepared global data
     * @return array - The table`s nodes
     * */
    buildPageTable(chunkedItems, currentChunkIndex, data) {
        // helpers
        let noBorder = {border: [false, false, false, false]};

        let subTotalCount = sumBy(chunkedItems, 'count');
        let subTotalCost = Formatter.asDecimal(sumBy(chunkedItems, 'total'));
        let tableSubtotal = [[
            {text: 'Итого', colSpan: 7, alignment: 'right', ...noBorder},
            '', '', '', '', '', '',
            {text: subTotalCount, alignment: 'center'},
            '', '',
            {text: 'X', alignment: 'center'},
            {text: subTotalCost, alignment: 'right'},
            {text: 'X', alignment: 'center'},
            '',
            {text: subTotalCost, alignment: 'right'},
        ]];

        // add total on last page
        if (currentChunkIndex === (data.pagesCount - 1)) {
            tableSubtotal.push([
                {text: 'Всего по накладной', colSpan: 7, alignment: 'right', ...noBorder},
                '', '', '', '', '', '',
                {text: data.itemsCount, alignment: 'center'},
                '', '',
                {text: 'X', alignment: 'center'},
                {text: data.totalByValue, alignment: 'right'},
                {text: 'X', alignment: 'center'},
                '',
                {text: data.totalByValue, alignment: 'right'},
            ]);
        }

        /**
         * Forming table chunk bodies per pages
         * */
        let tableBody = chunkedItems.map((item) => [
            {text: `${findIndex(data.order.items, item) + 1}`, alignment: 'right'},
            item.title,
            item.code || '',
            {text: 'шт', alignment: 'center'},
            {text: '796', alignment: 'center'},
            {text: 'шт', alignment: 'center'},
            {text: '1', alignment: 'center'},
            {text: item.count, alignment: 'center'},
            '',
            {text: item.count, alignment: 'center'},
            {text: Formatter.asDecimal(item.cost), alignment: 'right'},
            {text: Formatter.asDecimal(item.total), alignment: 'right'},
            {text: data.ndsText, alignment: 'right'},
            {text: data.nds, alignment: 'right'},
            {text: Formatter.asDecimal(item.total), alignment: 'right'},
        ]);

        return [
            {text: `Страница ${currentChunkIndex + 1}`, alignment: 'right', style: 'OrderPdfPrinterLegal_pageNumber', ...(currentChunkIndex > 0 ? {pageBreak: 'before'} : {})},
            {
                table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 3,
                    //        1   2    3   4   5  6   7   8   9   10  11  12  13  14  15
                    widths: [15, '*', 45, 30, 30, 30, 30, 30, 30, 40, 40, 40, 40, 30, 40],

                    body: [
                        [
                            {text: '#', alignment: 'center', rowSpan: 2},
                            {text: 'Товар', alignment: 'center', colSpan: 2},
                            '',
                            {text: 'Единица измерения', alignment: 'center', colSpan: 2},
                            '',
                            {text: 'Вид упаковки', alignment: 'center', rowSpan: 2},
                            {text: 'Количество', alignment: 'center', colSpan: 2},
                            '',
                            {text: 'Масса брутто', alignment: 'center', rowSpan: 2},
                            {text: 'Коли- чество (масса нетто)', alignment: 'center', rowSpan: 2},
                            {text: 'Цена, руб. коп.', alignment: 'center', rowSpan: 2},
                            {text: 'Сумма без учета НДС, руб. коп.', alignment: 'center', rowSpan: 2},
                            {text: 'НДС', alignment: 'center', colSpan: 2},
                            '',
                            {text: 'Сумма с учетом НДС, руб. коп.', alignment: 'center', rowSpan: 2},
                        ],
                        [
                            '',
                            {text: 'Наименование, характеристика, сорт, артикул товара', alignment: 'center'},
                            {text: 'код', alignment: 'center'},
                            {text: 'Наименование', alignment: 'center'},
                            {text: 'код по ОКЕИ', alignment: 'center'},
                            '',
                            {text: 'в одном месте', alignment: 'center'},
                            {text: 'мест, штук', alignment: 'center'},
                            '',
                            '',
                            '',
                            '',
                            {text: 'ставка, %', alignment: 'center'},
                            {text: 'сумма, руб. коп.', alignment: 'center'},
                            ''
                        ],
                        [
                            {text: '1', alignment: 'center'},
                            {text: '2', alignment: 'center'},
                            {text: '3', alignment: 'center'},
                            {text: '4', alignment: 'center'},
                            {text: '5', alignment: 'center'},
                            {text: '6', alignment: 'center'},
                            {text: '7', alignment: 'center'},
                            {text: '8', alignment: 'center'},
                            {text: '9', alignment: 'center'},
                            {text: '10', alignment: 'center'},
                            {text: '11', alignment: 'center'},
                            {text: '12', alignment: 'center'},
                            {text: '13', alignment: 'center'},
                            {text: '14', alignment: 'center'},
                            {text: '15', alignment: 'center'},
                        ],
                        ...tableBody,
                        ...tableSubtotal
                    ]
                }
            }
        ];
    }

}