<script>
/**
 * The Order`s Calendar
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

import BaseStore from "../../models/BaseStore";
import moment from "moment/moment";
import filter from "lodash/filter";

export default {
    name: "Calendar",
    data() {
        return {
            moment,
            selectedDate: new Date(),
            ordersStore: new BaseStore([], {
                entityName: 'order',
                fetchParams: {
                    limit: 0,
                    fields: 'delivery_date'
                }
            })
        }
    },
    computed: {
        range: vm => [
            moment(vm.selectedDate).startOf("month").startOf("week").format("Y-MM-DD"),
            moment(vm.selectedDate).endOf("month").endOf("week").format("Y-MM-DD")
        ],
        currentMonth: vm => moment(vm.selectedDate).format("Y-MM"),
        cellData: function () {
            let data = {};
            this.ordersStore.each(m => {
                data[m.delivery_date] = data[m.delivery_date] && data[m.delivery_date] + 1 || 1;
            });
            return data;
        },
        // string representation of selectedDate
        computedSelectedDay: vm => moment(vm.selectedDate).format("Y-MM-DD")
    },
    watch: {
        currentMonth: function () {
            this.prepareData();
        }
    },
    methods: {
        prepareData() {
            this.ordersStore.applyFilter("delivery_date", {
                property: 'delivery_date',
                operator: 'between',
                value: this.range
            });
            this.ordersStore.fetch();
        },
        getOrders(dateStr) {
            return filter(this.ordersStore.models, {delivery_date: dateStr});
        },
        onCellClick(date) {
            this.selectedDate = date;
            const day = this.computedSelectedDay;
            let hasOrders = this.cellData[day];
            if (hasOrders) {
                this.$router.push({
                    name: 'order-daylist',
                    params: {
                        day: day
                    }
                })
            }
        }
    },
    created() {
        this.prepareData();
    }
}
</script>


<template>
    <div class="humanize-container">
        <el-calendar v-model="selectedDate">
            <template v-slot:dateCell="{date, data}">
                {{ moment(date).format("D") }}
                <div v-if="cellData[data.day]">
                    <el-tag @click="onCellClick(date)"> + {{ cellData[data.day] }}</el-tag>
                </div>
            </template>
        </el-calendar>
    </div>
</template>


<style lang="scss" scoped>
</style>