<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import BaseStore from "../../models/BaseStore";
import SimpleList from "../../components/SimpleList";
import Order from "../../models/Order";
import OrderListItem from "../order/ListItem";
import CourierSelect from "../../components/CourierSelect";
import OrderListToolbar from "./OrderListToolbar";
import OrderListCouriersExcelPrinter from "./OrderListCouriersExcelPrinter";
import OrderListShipmentExcelPrinter from "./OrderListShipmentExcelPrinter";
import size from "lodash/size";
import OrderPdfPrinterWrapper from "@/models/OrderPdfPrinterWrapper.js";

export default {
    name: "OrderList",
    components: {
        OrderListToolbar,
        CourierSelect,
        SimpleList,
        OrderListItem
    },
    props: {
        day: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            orders: new BaseStore([], {
                model: Order,
                autoLoad: true,
                autoFilter: true,
                autoSort: true,
                sorter: {
                    property: 'created_at',
                    direction: BaseStore.SORT_DESC
                },
                internalFilters: {
                    delivery_date: {
                        property: 'delivery_date',
                        value: this.day
                    }
                },
                fetchParams: {
                    limit: 0,
                    fields: 'id,number,delivery_address,manager_notes,total,delivery_date,client_name,client_phone,client_notes',
                    expand: 'statusText,payStatusText,counterpartyName,courierName,deliveryText,items,counterparty'
                }
            }),
            groupErrors: {},
            isLoading: false
        }
    },
    methods: {
        async onShipmentPrintClick() {
            this.isLoading = true;
            let printer = new OrderListShipmentExcelPrinter(this.day, this.orders);
            await printer.writeFile(`Отгрузка_${this.day}.xlsx`);
            this.isLoading = false;
        },
        async onCourierPrintClick() {
            this.isLoading = true;
            let printer = new OrderListCouriersExcelPrinter(this.day, this.orders);
            await printer.writeFile(`Курьеры_${this.day}.xlsx`);
            this.isLoading = false;
        },
        async onSendToDeliveryClick(selectedOrders) {
            this.isLoading = true;
            let groupErrors = {};
            try {
                await Promise.all(selectedOrders.map(order => {
                    return order.actionSendToDelivery({
                        params: {
                            fields: 'status',
                            expand: 'statusText'
                        }
                    }).catch(e => {
                        if (order.hasErrors()) {
                            groupErrors[order.id] = order.getFirstErrorMesage();
                        }
                    });
                }));
            } finally {
                this.isLoading = false;
                this.groupErrors = groupErrors;
                if (!size(groupErrors)) {
                    this.$message.success("Все заказы успешно переданы в доставку");
                } else if (size(groupErrors) < size(selectedOrders)) {
                    this.$message.info("Некоторые заказы успешно переданы в доставку");
                } else {
                    this.$message.error("Все заказы завершились с ошибками");
                }
            }
        },
        getSelectedPluralText(selectedOrders) {
            let plural = 'заказ';
            if (1 < selectedOrders.length && selectedOrders.length < 5) {
                plural = 'заказа';
            }
            if (selectedOrders.length >= 5) {
                plural = 'заказов';
            }
            return `${selectedOrders.length} ${plural}`;
        }
        , async onPrintClick(models) {
            this.isLoading = true;
            const printer = OrderPdfPrinterWrapper.createPrinter(models);
            printer.printPdf({
                info: {
                    title: `Групповая печать ${this.getSelectedPluralText(models)} на ${this.day}`
                }
            });
            this.isLoading = false;
        }
    }
}
</script>


<template>
    <div v-loading="isLoading">
        <div class="toolbar">
            <div class="grow">
                <el-button type="info"
                           icon="el-icon-download"
                           @click="onCourierPrintClick">
                    Курьеры.xlsx
                </el-button>
                <el-button type="info"
                           icon="el-icon-download"
                           @click="onShipmentPrintClick">
                    Отгрузка.xlsx
                </el-button>
            </div>
            <div>
                <order-list-toolbar :store="orders"></order-list-toolbar>
            </div>
        </div>
        <simple-list :store="orders"
                     name-prop="number"
                     details-route="order-basic"
                     has-group-operations
                     :filterable="false">

            <template v-slot:item="{record}">
                <div class="errorText" v-if="groupErrors[record.id]">
                    <el-icon class="el-icon-warning"></el-icon>
                    {{ groupErrors[record.id] }}
                </div>
                <order-list-item :order="record"></order-list-item>
            </template>

            <template v-slot:toolbar-alternate="{selectedModels}">
                <div class="toolbar-item">{{ getSelectedPluralText(selectedModels) }}:</div>
                <div class="toolbar-item">
                    <el-popconfirm :title="`${getSelectedPluralText(selectedModels)} будут отправлены в доставку. Клиенты будут автоматически оповещены.`"
                                   cancel-button-text="Отмена"
                                   confirm-button-text="Передать в доставку"
                                   @confirm="onSendToDeliveryClick(selectedModels)">
                        <el-button slot="reference"
                                   size="small"
                                   type="primary"
                                   icon="el-icon-truck">Передать в доставку
                        </el-button>
                    </el-popconfirm>
                </div>
                <div class="toolbar-item">
                    <el-button size="small"
                               type="action"
                               icon="icon el-icon-printer"
                               @click="onPrintClick(selectedModels)">Печать тов. чеков
                    </el-button>
                </div>
            </template>
        </simple-list>
    </div>
</template>

<style scoped lang="scss">
@import "../../scss/vars";

.order-list-item {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: space-between;


    > * + * {
        margin-left: 10px;
    }

    .title {
        flex: 1;
    }

    .price {
        font-size: larger;
        white-space: nowrap;
        font-weight: 500;
        width: 120px;
        text-align: right;
    }

    .status {
        line-height: 14px;
        white-space: nowrap;
        width: 150px;

        &--pay {
            font-style: italic;
            color: silver;
        }
    }
}

.errorText {
    color: $accent-color;
}
</style>