/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import ExcelPrinter from "../../utils/ExcelPrinter";
import Formatter from "../../utils/Formatter";
import each from "lodash/each";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";

export default class OrderListCouriersExcelPrinter extends ExcelPrinter {

    /**
     * @param date string - The calendar`s date
     * @param store BaseStore - The filtered store of orders
     * */
    constructor(date, store) {
        super();
        this.date = date;
        this.store = store;
    }


    /**
     * Prepare data from store: grouping by couriers
     * */
    getData() {
        let ordersData = this.store.toJSON();
        return groupBy(ordersData, 'courierName');
    }


    getTitle() {
        return `Курьеры на ${Formatter.asDate(this.date, "LL")}`;
    }


    getColumns() {
        return [
            {header: 'Курьер', key: 'courier', width: 25},
            {header: 'Заказ', key: 'order', width: 15},
            {header: 'Клиент', key: 'client', width: 25},
            {header: 'Адрес', key: 'address', width: 30},
            {header: 'Примечания', key: 'notes', width: 30},
        ]
    }

    applyDataRow(worksheet, courierOrdersStack, courierName) {
        if (isEmpty(courierName)) {
            return;
        }
        each(courierOrdersStack, (order, index) => {
            let isFirst = index === 0;
            let clientText = [
                (order.client_name || order.counterpartyName),
                order.client_phone
            ].join(",\r\n");

            let row = worksheet.addRow({
                courier: isFirst ? courierName : null,
                order: order.number,
                client: clientText,
                address: order.delivery_address,
                notes: order.client_notes
            });

            row.alignment = {vertical: 'top', wrapText: true};
        });
    }
}