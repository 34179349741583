<script>
    /**
     * Remote select counterparty
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import BaseStore from "../models/BaseStore";
    import compact from "lodash/compact";
    import Courier from "../models/Courier";

    export default {
        name: "CourierSelect",
        props: {
            value: Number | String | Array
        },
        data() {
            return {
                innerValue: this.value,
                store: new BaseStore([], {
                    model: Courier,
                    sorters: [
                        {
                            property: 'shortname',
                            direction: 'DESC'
                        }
                    ],
                    fetchParams: {
                        limit: 5,
                        filterText: ''
                    }
                })
            }
        },
        watch: {
            // update inner value when prop changed
            value: function (value) {
                this.innerValue = value;
            },
            innerValue: function (val) {
                this.$emit('input', val);
            }
        },
        methods: {
            autoLoadValue(val) {
                this.store.setOption("filters", [
                    {
                        property: 'id',
                        value: val
                    }
                ]);
                this.store.fetch().then(() => this.innerValue = val);
            },
            fetchOptions(query) {
                //prevent from auto fetching with empty query
                if (query === '') {
                    return;
                }

                // remove filters
                this.store.setOption("filters", []);
                this.store.setOption("fetchParams", {
                    ...this.store.getOption("fetchParams"),
                    filterText: query
                });
                this.store.fetch();
            },
            getMetaString(model) {
                return compact([
                    model.email,
                    model.phone,
                    model.notes
                ]).join(", ");
            },
            onChange(val) {
                // if select the model, emit the parent to do actions
                if (val) {
                    this.$emit('modelselect', this.store.find({id: val}));
                }
            }
        },
        created() {
            if (this.innerValue) {
                this.autoLoadValue(this.innerValue);
            }
        }
    }
</script>


<template>
    <el-select v-model="innerValue"
               remote
               clearable
               filterable
               @change="onChange"
               :loading="store.loading"
               :remote-method="fetchOptions"
               placeholder="Имя, фамилия, телефон, email..."
               v-bind="$attrs">
        <template slot="prefix">
            <i class="el-input__icon el-icon-truck"></i>
        </template>
        <el-option v-for="option in store.models"
                   :label="option.shortname"
                   :value="option.id"
                   :key="option.id">
            <div class="name">{{option.shortname}}: {{option.fullname}}</div>
            <div class="metas" v-html="getMetaString(option)"></div>

        </el-option>
    </el-select>
</template>


<style scoped lang="scss">
    .el-select-dropdown {
        &__item {
            height: auto;

            .name {
                line-height: 20px;
            }

            .metas {
                line-height: 12px;
                font-size: 12px;
                color: silver;
                font-weight: 300;
            }
        }
    }
</style>