<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import Formatter from "../../utils/Formatter";

    export default {
        name: "DateView",
        props: {
            day: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                i18nDay: Formatter.asDate(this.day, "LL")
            }
        },
        beforeRouteEnter(to, from, next) {
            // autoredirect to first tab
            if (to.name.indexOf("orderCalendar-") < 0) {
                // use next with callback to prevent "uncaught error undefined" by calling next twice
                next(vm => {
                    vm.$router.push({
                        name: 'orderCalendar-shipmentNomenclature',
                        params: to.params,
                        replace: true
                    });
                });
            } else {
                next();
            }
        }
    }
</script>


<template>
    <div class="humanize-container--long">
        <el-page-header title="Календарь"
                        @back="$router.push({name:'order-calendar'})"
                        :content="i18nDay"></el-page-header>

        <el-menu :router="true" :default-active="$route.name" mode="horizontal">
            <el-menu-item index="orderCalendar-shipmentNomenclature" :route="{name:'orderCalendar-shipmentNomenclature'}">
                Отгрузка
            </el-menu-item>
            <el-menu-item index="orderCalendar-orders" :route="{name:'orderCalendar-orders'}">
                Заказы
            </el-menu-item>
        </el-menu>

        <div class="humanize-container--long">
            <router-view></router-view>
        </div>
    </div>
</template>


<style scoped>

</style>