<script>
    /**
     * List of products at the calendar`s date
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import ShipmentNomenclatureTable from "./ShipmentNomenclatureTable";
    import Formatter from "../../utils/Formatter";
    import ShipmentToolbar from "./ShipmentToolbar";
    import BaseStore from "../../models/BaseStore";
    import ShipmentPdfPrinter from "./ShipmentPdfPrinter";

    export default {
        name: "ShipmentNomenclature",
        components: {
            ShipmentToolbar,
            ShipmentNomenclatureTable
        },
        props: {
            day: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
                title: `Отгрузка на ${Formatter.asDate(this.day)}`,
                store: new BaseStore([], {
                    autoFilter: true,
                    autoLoad: true,
                    fetchUrl: '/admin/order-calendar/shipment-nomenclature',
                    fetchParams: {
                        date: this.day,
                        limit: 0
                    }
                })
            }
        },
        computed: {
            nomenclature: vm => vm.store.models
        },
        methods: {
            async onPrintClick() {
                this.isLoading = true;
                let printer = new ShipmentPdfPrinter(this.day, this.store);
                await printer.printPdf();
                this.isLoading = false;
            }
        }
    }
</script>


<template>
    <div v-loading="store.loading || isLoading">
        <div class="toolbar">
            <div class="grow">
                <el-button type="info"
                           icon="el-icon-printer"
                           @click="onPrintClick">
                    Печать
                </el-button>
            </div>
            <shipment-toolbar :store="store"></shipment-toolbar>
        </div>
        <shipment-nomenclature-table :nomenclature="nomenclature"></shipment-nomenclature-table>
    </div>
</template>


<style scoped>

</style>