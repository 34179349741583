/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import Formatter from "../utils/Formatter.js";
import Order from "./Order.js";
import numberToWordsRu from "number-to-words-ru";
import OrderPdfPrinter from "@/models/OrderPdfPrinter.js";

export default class OrderPdfPrinterIndividual extends OrderPdfPrinter {
    getPdfDefinitions() {
        return {
            ...super.getPdfDefinitions(),
            pageOrientation: 'portrait'
        }
    }

    getPdfStyles() {
        return {
            OrderPdfPrinterIndividual_header: {
                fontSize: 14,
                bold: true,
            },
            OrderPdfPrinterIndividual_bold: {
                bold: true,
            },
            OrderPdfPrinterIndividual_underline: {
                decoration: 'underline'
            },
            OrderPdfPrinterIndividual_fieldName: {
                alignment: 'center',
                fontSize: 8,
                color: 'gray'
            }
        }
    }


    getTitle() {
        let orderModel = this.order;
        return `Товарный чек №${orderModel.number} от ${Formatter.asDate(orderModel.delivery_date, "LL")}`;
    }


    prepareData() {
        let order = this.order;

        return {
            order: {...order.attributes},
            vendorText: 'Крестьянское (фермерское) хозяйство Богачева Алла Константиновна, ИНН 782703656689, 188800, ' +
                'Ленинградская обл, Выборгский р-н, Выборг г, Вокзальная ул, дом 7, квартира 29',
            customerText: `${order.client_name}, ${order.delivery_address}, ${order.client_phone}`,
            totalText: `Всего наименований ${order.items.length}, на сумму ${Formatter.asCurrency(order.total)}`,
            courierText: order.courierName || null,
            totalByWords: numberToWordsRu.convert(order.total)
        }
    }

    getPdfContent(data) {
        let orderModel = data.order;

        let counterparties = [{
            table: {
                headerRows: 1,
                widths: [70, '*'],
                body: [
                    ['Поставщик:', {text: data.vendorText, style: 'OrderPdfPrinterIndividual_bold'}],
                    // ['', {text: orderModel.storehouse, style: 'OrderPdfPrinterIndividual_bold'}],
                    ['Покупатель:', {text: data.customerText, style: 'OrderPdfPrinterIndividual_bold'}],
                ]
            },
            layout: 'noBorders'
        }];


        const columnsWithQR = [{
            columns: [
                {
                    width: '*',
                    stack: [
                        {
                            text: this.getTitle(),
                            style: ['OrderPdfPrinterIndividual_header', 'OrderPdfPrinterIndividual_underline'],
                            margin: [0, 0, 0, 10],
                        },
                        ...counterparties
                    ]
                },
                {
                    width: 'auto',
                    // text: data.courierText,
                    margin: [0, -15, 0, 0],
                    style: ['OrderPdfPrinterIndividual_header', {
                        alignment: 'right'
                    }],
                    stack: [
                        data.courierText,
                        {
                            qr: JSON.stringify({order: {token: data.order.access_token}}),
                            fit: 100
                        }
                    ]
                }
            ],
            columnGap: 10,
            margin: [0, 0, 0, 10],
        }];


        let tableBody = orderModel.items.map((item, i) => {
            return [
                `${i + 1}`,
                item.code || '',
                item.title,
                {text: item.count, alignment: 'right'},
                {text: Formatter.asCurrency(item.cost), alignment: 'right'},
                {text: Formatter.asCurrency(item.total), alignment: 'right'}
            ]
        });

        let table = [
            {
                margin: [0, 10],
                table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: [30, 50, '*', 80, 50, 50],
                    body: [
                        [
                            {text: '#', alignment: 'center', style: 'OrderPdfPrinterIndividual_bold'},
                            {text: 'Код', alignment: 'center', style: 'OrderPdfPrinterIndividual_bold'},
                            {text: 'Товар', alignment: 'center', style: 'OrderPdfPrinterIndividual_bold'},
                            {text: 'Количество', alignment: 'center', style: 'OrderPdfPrinterIndividual_bold'},
                            {text: 'Цена', alignment: 'center', style: 'OrderPdfPrinterIndividual_bold'},
                            {text: 'Сумма', alignment: 'center', style: 'OrderPdfPrinterIndividual_bold'}
                        ],
                        ...tableBody
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                    }
                }
            }
        ];

        let totals = [{
            margin: [0, 0],
            layout: 'noBorders',
            table: {
                widths: ['*', 70],
                body: [
                    [
                        {text: 'Доставка:', alignment: 'right'},
                        {
                            text: orderModel.delivery_type !== Order.DELIVERY_PICKUP ?
                                Formatter.asCurrency(orderModel.delivery_cost) : 'Самовывоз'
                            , alignment: 'right'
                        }
                    ],
                    [
                        {text: 'Сумма:', alignment: 'right'},
                        {text: Formatter.asCurrency(orderModel.total), alignment: 'right'}
                    ]
                ]
            }
        }];


        let checkout = [
            {text: data.totalText, style: 'OrderPdfPrinterIndividual_small'},
            {text: data.totalByWords, style: ['OrderPdfPrinterIndividual_bold', 'OrderPdfPrinterIndividual_underline']}
        ];


        let signs = [{
            margin: [0, 10],
            columns: [
                {
                    width: '50%',
                    text: 'Выдал _______________ Богачева А.К.'
                },
                {
                    width: '50%',
                    text: `Получил _______________ ${orderModel.client_name}`
                }
            ]
        }];


        return [
            // ...header,
            // ...counterparties,
            ...columnsWithQR,
            ...table,
            ...totals,
            ...checkout,
            ...signs
        ];
    }
}