/**
 * Methods for creation logic for PdfPrintMixin it separate file
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import each from "lodash/each";
import PdfPrinter from "../../models/PdfPrinter.js";
import Formatter from "../../utils/Formatter";

export default class ShipmentPdfPrinter extends PdfPrinter {

    /**
     * @param date string - The calendar`s date
     * @param store BaseStore - The filtered store of orders
     * */
    constructor(date, store) {
        super();
        this.date = date;
        this.store = store;
    }

    /**
     * @inheritDoc
     * */
    prepareData() {
        let itemsData = this.store.models;
        return groupBy(itemsData, (item) => item.vendorText || 'Прочее');
    }

    /**
     * @inheritDoc
     * */
    getTitle() {
        return `Отгрузка по цехам на ${Formatter.asDate(this.date, "LL")}`;
    }

    /**
     * @inheritDoc
     * */
    getPdfContent(data) {
        return [
            {
                layout: 'lightHorizontalLines',
                table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,
                    widths: ['80%', '20%'],
                    body: [
                        [
                            {text: 'Номенклатура', alignment: 'center', style: 'bold'},
                            {text: 'Отгрузка', alignment: 'center', style: 'bold'}
                        ],
                        ...this.getPdfContentBody(data)
                    ]
                }
            }
        ];
    }

    /**
     * @inheritDoc
     * */
    getPdfDefinitions() {
        return {
            ...super.getPdfDefinitions(),
            pageOrientation: 'portrait'
        }
    }


    getPdfContentBody(groupedByVendor) {
        return reduce(groupedByVendor, (result, items, vendor) => {
            result.push([{
                text: vendor,
                style: 'vendor-name',
                alignment: 'left'
            }, ""]);

            each(items, (item) => {
                result.push([{
                    text: item.name,
                    alignment: 'left',
                    style: 'item-name'
                }, {
                    text: item.count,
                    alignment: 'center'
                }]);
            });
            return result;
        }, []);
    }


    /**
     * @inheritDoc
     * */
    getPdfStyles() {
        return {
            header: {
                fontSize: 14,
                bold: true
            },
            'bold': {
                bold: true,
            },
            'vendor-name': {
                bold: true,
                display: "block",
                margin: [0, 10, 0, 0]
            },
            'item-name': {
                margin: [20, 0, 0, 0],
            }
        };
    }


    /**
    * @inheritDoc
    * */
    getHeader() {
        return (currentPage, pageCount, pageSize) => {
            let columns = [{
                width: 'auto',
                text: this.getTitle(),
                alignment: 'left',
                style: 'header'
            }];
            if (pageCount > 1) {
                columns.push({
                    width: '*',
                    text: `, стр. ${currentPage}`,
                    margin: [0, 5]
                });
            }
            return [
                {
                    margin: [40, 10, 0, 0],
                    columns: columns
                }
            ];
        }
    }
}