/**
 * Wrapper for printing for multiple orders
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import OrderPdfPrinterIndividual from "@/models/OrderPdfPrinterIndividual.js";
import OrderPdfPrinterLegal from "@/models/OrderPdfPrinterLegal.js";
import {isArray} from "lodash";
import Order from "@/models/Order.js";
import OrderPdfGroupPrinter from "@/models/OrderPdfGroupPrinter.js";

export default class OrderPdfPrinterWrapper {

    /**
     * @param {Order[]|Order} orders
     * @return {OrderPdfPrinter|OrderPdfGroupPrinter}
     * */
    static createPrinter(orders) {
        if (orders instanceof Order) {
            return this.__createInternal(orders);
        }

        if (isArray(orders)) {
            const printers = orders.map(o => this.__createInternal(o));
            return new OrderPdfGroupPrinter(printers);
        }

        throw new Error('Invalid argument type, expect Order or array of Orders');
    }


    /**
     * @private
     * */
    static __createInternal(order) {
        return !order.getCounterparty().getIsLegal() ?
            new OrderPdfPrinterIndividual(order) :
            new OrderPdfPrinterLegal(order);
    }

}
