/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import ExcelPrinter from "../../utils/ExcelPrinter";
import Formatter from "../../utils/Formatter";
import each from "lodash/each";
import compact from "lodash/compact";

export default class OrderListShipmentExcelPrinter extends ExcelPrinter {

    /**
     * @param date string - The calendar`s date
     * @param store BaseStore - The filtered store of orders
     * */
    constructor(date, store) {
        super();
        this.date = date;
        this.store = store;
    }


    /**
     * @inheritDoc
     * */
    getData() {
        return this.store.models;
    }


    /**
     * @inheritDoc
     * */
    getTitle() {
        return `Отгрузка на ${Formatter.asDate(this.date, "LL")}`;
    }


    /**
     * @inheritDoc
     * */
    getColumns() {
        return [
            {header: 'Заказ', key: 'order', width: 15},
            {header: 'ФИО', key: 'client_name', width: 35},
            {header: 'Позиции заказа', key: 'order_item', width: 50},
            {
                header: 'Кол-во', key: 'order_item_count', width: 10, style: {
                    alignment: {horizontal: 'left'}
                }
            },
            {
                header: 'Стоимость', key: 'order_item_cost', width: 15, style: {
                    alignment: {horizontal: 'left'}
                }
            }
        ]
    }


    /**
     * @inheritDoc
     * */
    applyDataRow(worksheet, order) {
        each(order.items, (item, index) => {
            let isFirst = index === 0;
            let clientText = isFirst ? compact([
                (order.client_name || order.counterpartyName),
                order.client_phone || null
            ]).join("\r\n") : null;

            if (isFirst && order.delivery_address) {
                clientText += `,\r\n${order.delivery_address}`;
            }

            let row = worksheet.addRow({
                order: isFirst ? order.number : null,
                client_name: clientText,
                order_item: `${index + 1}. ${item.title}`,
                order_item_count: item.count,
                order_item_cost: item.total
            });
            row.alignment = {vertical: 'top', wrapText: true};
            row.getCell(4).alignment = {horizontal: 'center'};
            row.getCell(5).alignment = {horizontal: 'left'};
        });

        let row = worksheet.addRow({
            order_item: 'Итого',
            order_item_cost: order.total
        });
        row.font = {bold: true};
    }
}