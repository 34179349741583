/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import PdfPrinter from "@/models/PdfPrinter.js";
import forEach from "lodash/forEach";
import reduce from "lodash/reduce";

export default class OrderPdfGroupPrinter extends PdfPrinter {

    /**
     * @param {OrderPdfPrinter[]} printers
     * */
    constructor(printers) {
        super();

        forEach(printers, printer => {
            if (!printer instanceof PdfPrinter) {
                throw new Error('Invalid printer type ' + typeof PdfPrinter);
            }
        });

        this._printers = printers;
    }

    getTitle() {
        return "Групповая печать";
    }

    getPdfStyles() {
        return reduce(this._printers, (result, printer) => {
            result = {...result, ...printer.getPdfStyles()}
            return result;
        }, {});
    }

    getPdfDefinitions() {
        return {
            ...super.getPdfDefinitions()
            // use first printers defs as defaults
            , pageOrientation: this._printers[0].getPdfDefinitions()['pageOrientation']
            , defaultStyle: {
                fontSize: 9,
                bold: false
            }
        };
    }

    getPdfContent(data = {}) {
        return reduce(this._printers, (result, printer, index) => {
            if (index > 0) {
                result.push({
                    pageOrientation: printer.getPdfDefinitions()['pageOrientation']
                    , pageBreak: 'before'
                    , text: ''
                });
            }
            result.push(printer.getPdfContent(printer.prepareData()));
            return result;
        }, []);
    }
}