import PdfPrinter from "@/models/PdfPrinter.js";
import Order from "@/models/Order.js";

/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
export default class OrderPdfPrinter extends PdfPrinter {
    /**
     * @param order Order - The Order`s model
     * */
    constructor(order) {
        super();

        if (!order || !(order instanceof Order)) {
            throw new Error('Order is required');
        }
        this.order = order;
    }
}