<script>
    /**
     * The sorter button for store
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    import BaseStore from "../models/BaseStore";
    import find from "lodash/find";
    import isEqual from "lodash/isEqual";

    export default {
        name: "StoreSorter",
        props: {
            // store to be filtered
            store: {
                type: BaseStore,
                require: true
            },

            /*
            * Available sorters to form sorters list
            * */
            sorterOptions: {
                type: Array,
                default() {
                    return [
                        {
                            id: 'created_asc',
                            title: 'Дата создания',
                            property: 'created_at',
                            direction: BaseStore.SORT_ASC
                        },
                        {
                            id: 'created_desc',
                            title: 'Недавно созданные',
                            property: 'created_at',
                            direction: BaseStore.SORT_DESC
                        }
                    ]
                }
            }
        },

        data() {
            return {
                optionSelected: null
            }
        },

        computed: {
            buttonText: vm => vm.optionSelected && vm.optionSelected.title || 'По умолчанию'
        },

        methods: {
            isEqual,
            onStoreSorterChange() {
                let currentSorter = this.store.getSorter();
                let optionSelected = find(this.sorterOptions, {property: currentSorter.property, direction: currentSorter.direction});
                if (optionSelected) {
                    this.optionSelected = optionSelected;
                }
            },
            handleCommand(option) {
                this.store.setSorter({
                    property: option.property,
                    direction: option.direction
                });
            }
        },

        created() {
            //apply sorter by startup
            this.onStoreSorterChange();
            this.store.on(BaseStore.EVENT_SORTCHANGE, this.onStoreSorterChange);
        }
    }
</script>


<template>
    <el-dropdown @command="handleCommand"
                 v-bind="$attrs">
        <el-button icon="el-icon-sort">
            {{buttonText}} <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>

        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="option in sorterOptions"
                              :key="option.id"
                              :command="option"
                              :disabled="isEqual(option, optionSelected)">{{option.title}}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>

</template>


<style scoped>

</style>