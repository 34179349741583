/**
 * Base class to print with pdf
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 *
 * @see https://pdfmake.github.io/docs
 * @see http://pdfmake.org/playground.html
 */
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class PdfPrinter {

    /**
     * @return mixed - The prepared data to be used
     * */
    prepareData() {
        return {};
    }

    createPdf(extraDocDefinitions = {}) {
        // console.log(this.getPdfDefinitions());
        return pdfMake.createPdf({
            ...this.getPdfDefinitions(),
            ...extraDocDefinitions
        });
    }

    printPdf(extraDocDefinitions = {}) {
        this.createPdf(extraDocDefinitions).print();
    }

    openPdf(extraDocDefinitions = {}) {
        this.createPdf(extraDocDefinitions).open();
    }

    getPdfDefinitions() {
        let data = this.prepareData();

        return {
            info: {
                title: this.getTitle()
            },
            content: this.getPdfContent(data),
            styles: this.getPdfStyles(),
            header: this.getHeader(),
            footer: this.getFooter(),
            pageOrientation: 'landscape',
            pageSize: 'A4',
            pageMargins: [40, 40, 40, 40],
            defaultStyle: {
                fontSize: 10,
                bold: false
            }
        }
    }


    /**
     * Generate content
     * @param data
     * @return Array - The content to be passed as doc definition to pdfMake
     * */
    getPdfContent(data = {}) {
        return ["The Pdf Print Mixin"];
    }

    /**
     * @return Object - The css styles to be passed as second param to pdfMake
     * */
    getPdfStyles() {
        return {};
    }


    /**
     * Create string to use as title
     * @return string
     * */
    getTitle() {
        return 'PdfPrinter class';
    }


    /**
     * Get header`s definitions
     * @return string|function|object - The correct value
     * @see https://pdfmake.github.io/docs/0.1/document-definition-object/headers-footers/
     * */
    getHeader() {
        return {};
    }


    /**
     * Get footer`s definitions
     * @return string|function|object - The correct value
     * @see https://pdfmake.github.io/docs/0.1/document-definition-object/headers-footers/
     * */
    getFooter() {
        return {};
    }
}